import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <br/><br/>
        <a href="https://happy3d.se" style={{ color:`#FF6600` }}>Beställ 3D utskrifter på Happy 3D</a>
      </header>
    </div>
    
  );
}

export default App;
